// Generated by Framer (37fb21e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["x42FOtt4V", "yqYfzK_Id"];

const serializationHash = "framer-uEdH3"

const variantClassNames = {x42FOtt4V: "framer-v-nxi2uj", yqYfzK_Id: "framer-v-65r7p1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Click: "yqYfzK_Id", Default: "x42FOtt4V"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "x42FOtt4V"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "x42FOtt4V", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-nxi2uj", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"x42FOtt4V"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({yqYfzK_Id: {"data-framer-name": "Click"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 185, intrinsicWidth: 155, loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 0 + (((((componentViewport?.height || 24) - 0) - 23.870967741935484) / 2) + 0 + 0))), pixelHeight: 370, pixelWidth: 310, src: "https://framerusercontent.com/images/lOVKY89Qi4MgFcxsP1p1jAcWfk.png"}} className={"framer-5pwmsn"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"cBBFp3EoA"} {...addPropertyOverrides({yqYfzK_Id: {background: {alt: "", fit: "fill", intrinsicHeight: 185, intrinsicWidth: 155, loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 0 + (((((componentViewport?.height || 19) - 0) - 19.096774193548388) / 2) + 0 + 0))), pixelHeight: 370, pixelWidth: 310, src: "https://framerusercontent.com/images/lOVKY89Qi4MgFcxsP1p1jAcWfk.png"}}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-uEdH3.framer-1q36d24, .framer-uEdH3 .framer-1q36d24 { display: block; }", ".framer-uEdH3.framer-nxi2uj { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-uEdH3 .framer-5pwmsn { aspect-ratio: 0.8378378378378378 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); overflow: visible; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-uEdH3.framer-nxi2uj { gap: 0px; } .framer-uEdH3.framer-nxi2uj > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-uEdH3.framer-nxi2uj > :first-child { margin-top: 0px; } .framer-uEdH3.framer-nxi2uj > :last-child { margin-bottom: 0px; } }", ".framer-uEdH3.framer-v-65r7p1 .framer-5pwmsn { height: var(--framer-aspect-ratio-supported, 19px); width: 16px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"yqYfzK_Id":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGpk7EKhUQ: React.ComponentType<Props> = withCSS(Component, css, "framer-uEdH3") as typeof Component;
export default FramerGpk7EKhUQ;

FramerGpk7EKhUQ.displayName = "Pointer";

FramerGpk7EKhUQ.defaultProps = {height: 24, width: 20};

addPropertyControls(FramerGpk7EKhUQ, {variant: {options: ["x42FOtt4V", "yqYfzK_Id"], optionTitles: ["Default", "Click"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerGpk7EKhUQ, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})